import api from '../../../api/api'
import { _orderByName } from '../../../utils/formatter'

export default {
  list: async (active = null) => {
    let data = await api.get.fazendas(active)

    data = data.map(item => {
      return {
        name: item.pessoajuridica.NomeFantasia,
        id: item.CodFazenda,
        ...item,
      }
    })

    return _orderByName({
      data,
      key: 'name',
    })
  },

  numberOfVisits: async (farmId, reportTypeId) => {
    let response = await api.get.numberOfVisits(farmId, reportTypeId)
    return response.data
  },
}
